<template>
  <div class="login_content" :style="{ height: height + 'px' }">
    <div class="login_banzhuan"></div>
    <div class="login-container">
      <el-form
        v-if="!StoreCodeShow"
        label-position="left"
        label-width="0px"
        class="demo-ruleForm login-page"
      >
        <h4 class="title">{{ this.StoreName }}</h4>
        <br />
        <el-form-item prop="username">
          <el-input
            type="text"
            v-on:keyup.enter="AccountEnter"
            v-model="AccountModel.Account"
            auto-complete="off"
            :placeholder="tools.GetLanguageValue('web.用户名')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            ref="passwordTxt"
            type="password"
            v-on:keyup.enter="login"
            v-model="AccountModel.Password"
            auto-complete="off"
            :placeholder="tools.GetLanguageValue('web.密码')"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item style="width: 100%">
          <el-button
            type="primary"
            style="width: 100%; height: 40px"
            @click="login"
            >{{ tools.GetLanguageValue("web.登录") }}</el-button
          >
        </el-form-item>
        <br />

        <el-button link @click="Register">
          {{ tools.GetLanguageValue("web.注册") }}
        </el-button>
      </el-form>

      <div
        v-else
        label-position="left"
        label-width="0px"
        class="demo-ruleForm login-page"
      >
        <h4 class="title">
          {{ tools.GetLanguageValue("web.请输入商店代号") }}
        </h4>
        <br />
        <el-form-item prop="username">
          <el-input
            type="text"
            v-model="this.StoreCode"
            auto-complete="off"
            v-on:keyup.enter="GoStore"
            :placeholder="tools.GetLanguageValue('web.商店代号')"
          ></el-input>
        </el-form-item>

        <br />
        <el-form-item style="width: 100%">
          <el-button
            type="primary"
            style="width: 100%; height: 40px"
            @click="GoStore"
          >
            {{ tools.GetLanguageValue("web.转到商店") }}
          </el-button>
        </el-form-item>
        <br />
        <el-button link @click="Register">
          {{ tools.GetLanguageValue("web.注册") }}
        </el-button>
      </div>
    </div>
    <div class="bottom">
      <div style="width: 500px" @click="ToPlatform">
        ©RepairNote Powered by 威视科技 - VisionTec 
      </div>
      <div style="width: 500px;margin-top: 10px;"  >
      <a href="javascript:void(0)" @click="ToAboutUs">About Us
      </a>
      </div>
      
    </div>
  </div>
</template>
<script >
import axios from "../../../commons/AxiosMethod.js";
import { ElMessageBox, ElMessage } from "element-plus";
import ProductItem from "../../../components/ProductItem.vue";
import tools from "../../../commons/tools.js";
export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      height: window.innerHeight,
      width: window.innerWidth,
      WebConfigs: {},
      AccountModel: {
        Account: "",
        Password: "",
        Checked: false,
        Code: "",
        AccountRole: [],
      },
      LanguageId: "",
      Languages: [],
      StoreCode: null,
      StoreName: null,
      StoreCodeShow: true,
      tools: tools,
    };
  },
  name: "App",
  mounted() {
    this.LanguageId = localStorage.getItem("LanguageId");
    this.CheckStore();
    this.VerificationStoreLogin();
    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.height = document.documentElement.clientHeight - 10;
      })();
    };
    this.height = document.documentElement.clientHeight - 10;
  },
  components: {
    ProductItem,
  },
  methods: {
    AccountEnter() {
      this.$refs.passwordTxt.focus();
    },
    VerificationStoreLogin() {
      axios.apiMethod("/Home/VerificationStoreLogin", "GET", {}, (result) => {
        if (result.Data.IsSuccess) {
          this.$router.push({
            path: "storeHome",
          });
        }
      });
    },
    //转到商店
    GoStore() {
      //验证是否存在商店

      if (this.StoreCode != null && this.StoreCode.length > 1) {
        axios.apiMethod(
          "/Home/VerificationStoreCode",
          "GET",
          {
            code: this.StoreCode,
          },
          (result) => {
            if (result.Data.IsSuccess) {
              this.StoreCodeShow = false;
              this.StoreName = result.Data.Model.Name;
            } else {
              ElMessageBox({
                title: tools.GetLanguageValue("web.提示"),
                message: result.Data.Msg,
                type: "error",
              });
            }
          }
        );
      } else {
        ElMessageBox({
          title: tools.GetLanguageValue("web.提示"),
          message: tools.GetLanguageValue("web.请输入商户号"),
          type: "error",
        });
      }
    },
    CheckStore() {
      this.StoreCode = localStorage.getItem("StoreCode");
    },
    //语言的列表ceshi
    GetLanguageList() {
      const param = {};
      axios.apiMethod(
        "/all/Language/GetLanguageList",
        "get",
        param,
        (result) => {
          this.Languages = result.Data;
          this.Languages.forEach((element) => {
            if (element.Id == this.LanguageId) {
              this.LanguageOpt = element;
              tools.GetLanguageById(element.Id);
            }
          });

          if (
            this.LanguageId == "" ||
            this.LanguageId == null ||
            this.LanguageId == undefined
          ) {
            this.Languages.forEach((element) => {
              if (element.Default) {
                this.LanguageOpt = element;
                localStorage.setItem("LanguageId", element.Id);
                tools.GetLanguageById(element.Id);
              }
            });
          }
        }
      );
    },

    //注册
    Register() {
      this.$router.push({
        path: "storeRegister",
      });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    login() {
      this.AccountModel.StoreCode = this.StoreCode;
      axios.apiMethod(
        "/stores/Identity/Login",
        "post",
        this.AccountModel,
        (s) => {
          if (s.IsSuccess) {
            localStorage.setItem("Authentication_Header", "Bearer " + s.Token);
            localStorage.setItem("User_Name", s.Name);
            localStorage.setItem("User_Image", s.Image);

            localStorage.setItem("UserInfo", JSON.stringify(s.UserInfo));
            localStorage.setItem("StoreCode", this.StoreCode);
            this.$router.push({
              path: "storeHome",
            });
          } else {
            if (s.IsExpire == true) {
              //是过期用户， 询问是否去续费

              ElMessageBox.confirm(
                tools.GetLanguageValue("web.已到期是否去续费"),
                "Warning",
                {
                  confirmButtonText: tools.GetLanguageValue("web.确定"),
                  cancelButtonText: tools.GetLanguageValue("web.取消"),
                  type: "warning",
                  title: tools.GetLanguageValue("web.提示"),
                }
              )
                .then(() => {
                  this.$router.push({
                    path: "renewApplyFor",
                    query: { storeId:  s.StoreId },
                  });
                })
                .catch(() => {});

              return;
            }

            ElMessageBox({
              title: tools.GetLanguageValue("web.提示"),
              message: s.Msg,
              type: "error",
            });
          }
        },
        (e) => {}
      );
    },
    ToPlatform() {
      this.$router.push({
        path: "adminlogin",
      });
    },
    ToAboutUs() {
      this.$router.push({
        path: "aboutUs",
      });
    },
    
  },
};
</script>


<style lang="less" scoped>
.login_content {
  width: 100%;
  background-image: url("../../../assets/images/storeAmind/storeLogin-back.png");
  background-size: cover;
  background-position: 50% 50%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .login_banzhuan {
    background-image: url("../../../assets/images/storeAmind/banzhuan.png");
    background-repeat: inherit;

    width: 800px;
    height: 800px;
    left: 200px;
    top: 200px;
    background-size: cover;
    background-position: 50% 50%;
    text-align: center;
  }

  .login-container {
    width: 600px;
    height: 400px;

    .login-page {
      -webkit-border-radius: 5px;
      width: 400px;
      border-radius: 5px;
      margin: 0 auto;
      padding: 35px 35px 15px;
      background: #fff;
      border: 1px solid #eaeaea;
      box-shadow: 0 0 25px #cac6c6;
    }
  }
}

label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}

.bottom {
  position: absolute;
  bottom: 5px; 
  width: 80%;
  margin-left: 15px;
  margin-bottom: 20px;
  
}
</style>
